.modalDiv {
  width: 80vw;
  position: absolute;
  bottom: 0;
  margin-left: -16px;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 12px 48px 4px;
  transform: translateY(0px);
  padding-bottom: 20px;
  max-height: 100%;
  z-index: 3;
  background: rgb(255, 255, 255);
  border-radius: 8px;
  transition: transform 320ms cubic-bezier(0.45, 1.29, 0.64, 1) 0s;
  overflow: auto;
  padding: 0px 16px 20px;
}
.headDiv {
  position: relative;
}
.heading {
  margin: 4px 0px;
  padding: 12px 0px;
  text-align: center;
  color: rgba(0, 0, 0, 0.85);
  font-size: 1.063rem;
  font-weight: 600;
  letter-spacing: 0.1px;
  line-height: 22px;
  position: relative;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
}
.cross {
  position: absolute;
  margin: 6px 8px;
  right: 10px;
  top: 8px;
  cursor: pointer;
}
.back {
  position: absolute;
  margin: 6px 8px;
  left: -7px;
  top: 8px;
  cursor: pointer;
  z-index: 999;
}
.content {
  padding: 8px 8px 4px;
}
.row {
  display: flex;
  gap: 10px;
  align-items: center;
  cursor: pointer;
  margin: 5px 0px;
}
.row:hover {
  background: rgba(0, 0, 0, 0.06);
}
.text {
  color: rgba(0, 0, 0, 0.85);
  font-size: 1rem;
  line-height: 1.25;
}
.input {
  background: transparent;
  border-radius: 8px;
  color: rgba(0, 0, 0, 0.98);
  font-size: 1rem;
  line-height: 20px;
  padding: 10px 14px;
  border: 1px solid rgba(0, 0, 0, 0.42);
  box-sizing: border-box;
  width: 100%;
  direction: ltr;
  outline: none;
}
.input:hover {
  outline: none;
}
.emailBtn {
  font-size: 1rem;
  font-weight: 500;
  border-radius: 8px;
  line-height: 20px;
  padding: 10px 17px;
  box-sizing: border-box;
  transition: background-color 100ms linear 0s, border-color 100ms linear 0s;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  cursor: pointer;
  width: 100%;
  margin: 10px 0px;
  outline: none;
  border: 1px solid rgb(231, 231, 231);
  color: rgba(0, 0, 0, 0.85);
}
.error {
  color: red;
  font-size: 12px;
}
.cancelEmail {
  font-size: 1rem;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.85);
  cursor: pointer;
  text-align: center;
}
.emailBtn:hover {
  outline: none;
}

@media only screen and (min-width: 480px) {
  .modalDiv {
    width: 380px;
  }
}
