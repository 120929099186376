.spacer_div {
  display: none !important;
  height: 0px !important;
  width: 0px !important;
}

.hideBox {
  /* visibility: hidden; */
  /* opacity: 0 !important; */
  /* z-index: -999999; */
  /* pointer-events: none; */
  /* height: 0px !important; */
  /* overflow: hidden !important; */
  /* width: 0px !important; */
  top: 200vh;
  left: 120vw;
  /* margin-left: 300vh !important; */
}

.mainDivUnread {
  background-color: rgba(225, 0, 0, 1);
  height: 20px;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  border-radius: 20px;
  position: absolute;
  top: 0px;
  right: 0px;
  transform: translateX(50%) translateY(-50%);
}

.mainDiv {
  padding: auto 10px;
  /* height: 100%; */
  height: 40px;
  background: #ffffff;
  /* box-shadow: 0px 6px 10px rgba(9, 9, 9, 0.4); */
  /* border-radius: 8px; */
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
}

/* .mainDiv:hover {
  transform: translate(0px, -5px);
  transition: all 0.25s ease-in;
} */

.fixedDiv {
  position: fixed;
  bottom: 15px;
  right: 21px;
  z-index: 999;
}

.iconBox {
  display: flex;
  align-items: center;
  gap: 8px;
}

.text {
  font-weight: bold;
  margin: 0px;
  padding: 0px;
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  font-size: 14px;
}

.chatContainer {
  /* height: 80vh; */
  transition: all 0.3s ease-in-out;
  /* transition: opacity 0.4s ease-in-out; */
  position: fixed;
  display: flex;
  flex-direction: column;
  /* right: 20px;
  bottom: 20px; */
  opacity: 1;
  /* width: min(95vw, 380px); */
  /* height: 88%; */
  /* box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2); */
  background-color: white;
  /* padding: 20px; */
  /* border-radius: 10px; */

  /* transition: all 0.3s ease-in-out; */
  position: fixed;
  overflow: hidden;
  /* height: var(--100vvh, 100vh); */
  height: 100dvh;
  min-height: 100dvh;
  max-height: 100dvh;
  /* height: -webkit-fill-available;
  min-height: -webkit-fill-available;
  max-height: -webkit-fill-available; */
  width: 100vw;
  top: 0;
  left: 0;
  /* bottom: 0;
    right: 0; */
  /* box-shadow: 0 0 0 0; */
  /* background-color: white; */
  /* padding: 20px; */
  /* padding-bottom: 100px; */
  /* border-radius: 0px; */

  transform-origin: 50% 100%;
  /* transition: opacity 0.2s linear, transform 0.2s cubic-bezier(0.165, 0.84, 0.44, 1); */
  /* transition-delay: 0.1s; */
  /* will-change: opacity, transform; */

  /* pointer-events: none; */
  /* visibility: hidden; */
  /* opacity: 0; */
  /* transform: scale3d(0.9, 0.9, 1) translate3d(0, 30px, 0); */
  /* opacity: 1;
    transform: scale3d(1, 1, 1) translate3d(0, 0, 0); */
}

.headDiv {
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin: 0px 0px 10px; */
  border-radius: 10px 10px 0px 0px;
  padding: 10px 16px;
  height: 75px;
}

.leftHeadDiv {
  color: white;
  display: flex;
  align-items: center;
}

.righttHeadDiv {
  display: flex;
  align-items: center;
}

.head {
  font-weight: bold;
  margin: 0px;
  padding: 0px;
  color: white;
  font-size: 1rem;
  /* font-weight: bold; */
  line-height: 19px;
}

.subHead {
  margin: 0px;
  padding: 0px;
  font-family: "Noto Sans Devanagari", "Noto Sans Tamil", "Noto Sans",
    sans-serif !important;
  font-size: 12px;
  line-height: 15px;
  color: #595959;
  opacity: 0.7;
}

.upperSection {
  flex-grow: 0;
  flex-basis: 80vh;
  flex-shrink: 1;
  overflow-y: auto;
  /* padding-bottom: 14px; */
  /* max-height: 80vh; */
  /* height: calc(0.4 * var(--100vvh, 100vh)); */
}

.search {
  margin: 0 10px;
  z-index: 2;
  position: relative;
  /* box-shadow: 1px 1px 6px 2px rgba(0, 0, 0, 0.1); */
  border: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  /* border: 1px solid rgba(0, 0, 0, 0.42); */
  border-radius: 8px;
  /* box-shadow: rgba(0, 0, 0, 0.04) 0px 12px 36px 0px; */
  transition: all 300ms ease-in-out 0s;
  background: rgb(255, 255, 255);
  color: rgba(0, 0, 0, 0.85);
  line-height: 1.25;
  outline: none;
  padding: 8px 8px 8px 12px;
  /* margin-top: 10px; */
}

.search:focus-visible {
  border: 0px;
}

.search input {
  border: none;
  outline: none;
  background: none;
  width: 100%;
  border-radius: 8px;
}

.sendBtn {
  background: none;
  outline: none;
  border: none;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s;
}

.sendBtn:focus {
  outline: none;
}

.messageContainer {
  /* padding-top:14px ;
  padding-bottom:14px ; */
  /* background-color: red; */
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
}

.messageContainer::-webkit-scrollbar {
  display: none;
}

.messageBottomMargin {
  /* 
  margin-bottom: 14px; 
  */

  margin-left: 10px;
  margin-right: 10px;
  padding-bottom: 14px;
}

.message {
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 15px;
  display: grid;
}

.message .messageContent {
  font-weight: bold;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  height: auto;
  width: fit-content;
  /* font-weight: bold; */
  /* min-width: 40px; */
  text-align: right;
  max-width: 90%;
  color: rgba(255, 255, 255, 0.95);
  display: flex;
  /* align-items: center; */
  margin-right: 5px;
  margin-left: 5px;
  justify-self: flex-end;
  border-radius: 8px;
  padding: 5px 10px;
  overflow-wrap: break-word;
  word-break: break-word;
  font-size: 0.9rem;
  /* font-family: system-ui; */
  background: #1b2057de;

  /* background: linear-gradient(137deg, rgba(3,12,16,1) 0%, rgba(87,104,112,1) 19%, rgba(119,139,149,1) 49%, rgba(119,139,149,0.3967787798713235) 88%);  */
  border-radius: 10px 10px 3px 10px;
  overflow: hidden;
  box-shadow: 1px 1px 6px 2px rgba(0, 0, 0, 0.1);
}

.timestamp {
  font-weight: bold;
  font-size: 0.75em;
  opacity: 0.7;
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 3px;
}

.usertimestamp {
  margin-left: auto !important;
}

.messageBox {
  height: 100%;
  /* padding-bottom: 14px; */
  /* height: 70vh; */
  /* height: calc(0.7 * var(--100vvh, 100vh)); */
  /* margin-top: 12px; */
  position: relative;
}

.res_n_popup {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  background-color: rgba(0, 0, 0, 0.3);
  /* filter: blur(100px); */
}

.res_n_popup_text_container {
  border-radius: 5px;
  background-color: white;
  padding: 10px;
  margin: 20px;
}

.res_n_btn {
  margin-top: 10px;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 5px;
  background-color: blue;
  color: white;
  outline: none;
  border: none;
}

.messageBoxBackgroundImageContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  filter: blur(100px);
}

.messageBoxBackgroundImage {
  width: 100%;
  height: 100%;
  flex: 1;
}

#you {
  justify-content: flex-end;
}

#you .messageContent {
  justify-content: flex-end;
}

.fileMessage {
  width: 100%;
  /* max-width: 277px; */
  /* max-height: 300px; */
  border-radius: 5px;
  /* border-radius: 4px; */

  /* margin-right: 5px;
  margin-left: 5px; */
  justify-self: flex-end;
  /* padding: 2px; */
}

.document {
  /* width: 190px;
  height: 120px; */
  border-radius: 4px;
  margin-right: 5px;
  margin-left: 5px;
  justify-self: flex-end;
  padding: 5px;
  overflow: hidden;
  position: relative;
}

.pdfDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.pdftext {
  font-size: 16px;
  position: absolute;
  bottom: 0;
  margin: 0px;
  width: 100%;
  padding: 4px;
}

#other {
  justify-content: flex-start;
}

.otherDiv {
  justify-content: flex-start;
  display: flex;
  flex-direction: column;
  gap: 18px;
}

.other {
  overflow: hidden;
  box-shadow: 1px 1px 6px 2px rgba(0, 0, 0, 0.1);
  height: auto;
  width: fit-content;
  font-weight: bold;
  /* min-width: 40px; */
  /* text-align: center; */
  max-width: 90%;
  color: rgba(0, 0, 0, 0.95);
  display: flex;
  /* align-items: center; */
  margin-right: 5px;
  margin-left: 5px;
  /* justify-self: flex-end; */
  /* border-radius: 8px; */
  overflow-wrap: break-word;
  word-break: break-word;
  line-height: 1.25;
  /* font-family: system-ui; */
  background-color: white;
  border-radius: 10px 10px 10px 3px;
  overflow: hidden;
  /* text-align: center; */
  font-size: 0.9rem;
  min-width: 40px;
  display: flex;
  flex-direction: column;
  /* font-weight: bold; */
  align-items: start;
  justify-content: start;
}

.res_btn {
  /* flex:1;
  text-align: center; */
  font-weight: bold !important;
  display: flex;
  /* flex-grow: 1; */
  justify-content: center;
  align-items: center;
  /* margin-left: 2px; */
  /* border: 1px solid rgba(0, 0, 0, 0.3); */
  font-size: 1rem;
  font-weight: 500;
  border-radius: 10px 10px 10px 10px;
  padding: 7px 12px;
  /* margin-bottom: -1px; */
  /* margin-top: -1px; */
  box-sizing: border-box;
  background-color: transparent;
  color: rgba(0, 0, 0, 0.85);
  cursor: pointer;
  /* width: fit-content; */
  background-color: white;
  box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.3);
  position: relative;
}

/* .res_btn::after {
  content: '';
  position: absolute;
  right: 15px;
  top:50%;
  transform: translateY(-50%);
  height: 10px;
  width: 10px;
  border-radius: 10px;
  background-color: rgba(34,73,251,0.7);

} */

.res_btn:hover {
  background-color: #f0f0f0;
  /* border-color: rgba(0, 0, 0, 0.75); */
}

.res_btn:active {
  /* color: rgba(0, 0, 0, 0.95);
  background-color: rgb(147, 119, 194);
  border-color: rgb(147, 119, 194); */
}

.title {
  padding: 0;
  margin: 10px 5px;
}

.btn_div_container {
  margin-top: 7px;
  overflow-x: auto;
  max-width: 365px;
  padding-top: 1px;
  padding-bottom: 1px;
}

.btn_div_scroll {
  width: 1600px;
}

.btn_div_alt {
  display: grid;
  /* flex-direction: column; */
  grid-template-columns: repeat(2, 1fr);
  padding: 0px 5px 2px 5px;
  gap: 9px;
  flex-wrap: wrap;
  /* align-items: stretch;
  justify-content: stretch; */
  background-color: linear-gradient(
    135deg,
    rgba(16, 57, 150, 0) 0%,
    rgba(16, 57, 150, 0.24) 100%
  );
}

.btn_div {
  display: flex;
  /* flex-direction: column; */
  padding: 0px 5px 2px 5px;
  gap: 9px;
  flex-wrap: wrap;
  /* align-items: stretch;
  justify-content: stretch; */
  background-color: linear-gradient(
    135deg,
    rgba(16, 57, 150, 0) 0%,
    rgba(16, 57, 150, 0.24) 100%
  );
}

.icon {
  display: flex;
  align-items: center;
  border-radius: 4px;
  padding: 6px;
  font-weight: bold;
}

.icon:hover {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.06);
}

.followbtn {
  border-color: rgb(201, 197, 197);
  background-color: transparent !important;
  border-radius: 0px !important;
  bottom: 25px !important;
  cursor: pointer;
  height: 12px !important;
  outline: 0;
  position: absolute;
  right: 20px;
  width: 12px !important;
  border-width: 0 3px 3px 0 !important;
  display: inline-block !important;
  padding: 3px !important;
  transform: rotate(45deg) !important;
}

@media only screen and (min-width: 480px) {
  /* .chatContainer {
    width: 380px;
    height: 87%;
  } */

  .message .messageContent {
    max-width: 295px;
    /* min-width: 64px; */
  }

  .messageBox {
    /* height: 70vh; */

    /* height: calc(0.7 * var(--100vvh, 100vh)); */
  }

  .other {
    width: 277px;
    max-width: 277px;
    min-width: 64px;
  }
}

.poweredBy {
  /* background-color: red; */
  text-align: center;
  margin: 10px;
  padding: 0;
  margin-bottom: 15px;
}

.poweredBy a {
  text-align: center;
  color: rgba(0, 0, 0, 0.7);
  font-size: 12px;
  /* font-style: italic; */
  text-decoration: none;
}

.pdfContainer {
  /* min-height: 165px;
  min-width: 277px; */
  max-height: 165px;
  max-width: 262px;
  /* padding: 10px; */
  box-sizing: border-box;
  overflow: hidden;
  /* background-color: rebeccapurple; */
  border-radius: 10px 10px 0px 0px;
}

.position_relative {
  position: relative;
  /* margin: 0px 10px; */
  /* background-color: black; */
}

.pdfIconContainer {
  display: flex;
  flex-direction: row;
}
.pdfIconDiv {
}
.pdfIconImg {
  width: 30px;
}
.pdfDownloadIcon {
  color: rgba(0, 0, 0, 0.65);
  display: flex;
  align-items: center;
  justify-content: center;
}
.pdfText {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 10px;
}

.pdfIconHref {
  text-decoration: none;
  color: black;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  background-color: rgba(0, 0, 0, 0.06);
  padding: 7px;
  border-radius: 10px;
  width: 100%;
  flex: 1;
}

.pdfFullRounded {

}

.pdfFullRounded {

}

.pdfIconHrefUser {
  background-color: rgba(255, 255, 255, 0.06);
  color: white;
}

.rightHeadDiv {
  color: white;
}
@media screen and (min-width: 768px) {
  /* .chatContainer {
  } */
}

@media screen and (max-width: 767px) {
  .chatContainer {
    transition: all 0.3s ease-in-out;
    position: fixed;
    overflow: hidden;
    /* height: var(--100vvh, 100vh); */
    height: 100dvh;
    min-height: 100dvh;
    max-height: 100dvh;
    height: -webkit-fill-available;
    min-height: -webkit-fill-available;
    max-height: -webkit-fill-available;
    width: 100vw;
    top: 0;
    left: 0;
    /* bottom: 0;
    right: 0; */
    box-shadow: 0 0 0 0;
    background-color: white;
    /* padding: 20px; */
    /* padding-bottom: 100px; */
    /* border-radius: 0px; */

    transform-origin: 50% 100%;
    transition: opacity 0.2s linear,
      transform 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
    transition-delay: 0.1s;
    will-change: opacity, transform;

    /* pointer-events: none; */
    visibility: hidden;
    opacity: 0;
    /* transform: scale3d(0.9, 0.9, 1) translate3d(0, 30px, 0); */
    /* opacity: 1;
    transform: scale3d(1, 1, 1) translate3d(0, 0, 0); */
  }

  .showBox {
    pointer-events: all;
    visibility: visible;
    opacity: 1;
    /* transform: scale3d(1, 1, 1) translate3d(0, 0, 0); */
  }

  .hideBox {
    /* visibility: hidden; */
    opacity: 0 !important;
    transform: scale3d(0.9, 0.9, 1) translate3d(0, 30px, 0);
    /* z-index: -999999; */
    /* pointer-events: none; */
    /* height: 0px !important; */
    /* overflow: hidden !important; */
    /* width: 0px !important; */
    /* margin-left: 300vh !important; */
  }
  .headDiv {
    border-radius: 0px 0px 0px 0px;
  }

  .spacer_div {
    display: block !important;
    height: 40px !important;
    width: 100% !important;
  }
}
