.container {
  width: 100%;
  /* height: 330px; */
  overflow-x: scroll;
  display: flex;
  gap: 12px;
  margin: 20px 0px;
  scroll-behavior: smooth;
  position: relative;
}
.container::-webkit-scrollbar {
  display: none;
}

.time_container {
  width: 100%;
  overflow-x: scroll;
  display: flex;
  gap: 8px;
  margin: 10px 0px;
  scroll-behavior: smooth;
  position: relative;
}
.time_container::-webkit-scrollbar {
  display: none;
}

.time_button {
  /* min-width: 250px;
  min-height: 250px; */
  background-color: white !important;
  color: rgba(0, 0, 0, 0.85);
  box-shadow: 1px 1px 6px 2px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 10px 14px;
  font-weight: normal;
  /* line-height: 1.25; */
  font-size: 0.8rem;
  text-align: center;
  /* font-family: system-ui; */
  cursor: pointer;
  border: 1px solid lightgrey !important;
}


.slide {
  border: "1px solid red";
  min-width: 250px;
  min-height: 325px;
  background-color: white !important;
  color: rgba(0, 0, 0, 0.85);
  box-shadow: 1px 1px 6px 2px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  /* padding-bottom: 10px; */
  font-weight: normal;
  line-height: 1.25;
  font-size: 1rem;
  /* font-family: system-ui; */
  cursor: pointer;
  border: 1px solid lightgrey;
}

.slick-next {
  background-color: black;
}
.backArrow {
  position: absolute;
  cursor: pointer;
  background: fff;
  border-radius: 50%;
  top: 45%;
  z-index: 99;
  background: white;
  padding: 5px;
}
.forwardArrow {
  position: absolute;
  cursor: pointer;
  background: fff;
  border-radius: 50%;
  top: 45%;
  z-index: 99;
  right: 0;
  background: white;
  padding: 5px;
}
.head {
  font-size: 16px;
  text-align: center;
  padding-top: 10px;
  /* border-top: 2px solid rgba(0, 0, 0, 0.1); */
  /* font-weight: 700; */
  /* text-decoration: underline; */
  /* width: fit-content; */
  /* text-transform: uppercase; */
}

.bookAppoinmentButton {
  background: #000000;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 0px 0px 5px 5px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  display: block; 
  font-size: 16px;
  /* margin-bottom: 10px;  */
  /* Add margin at the bottom for spacing */
}
.desc {
  font-size: 14px;
}

span {
  font-weight: 600;
  text-decoration: underline;
}

.doctorModal{
  background-color: white;
  border-width: 1px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  box-shadow: 1px 1px 6px 2px rgba(0, 0, 0, 0.1);
  /* border:rgba(0, 0, 0, 0.1) 1px solid; */
}
.doctorModal::backdrop {
  background-color: rgba(0, 0, 0, 0.85);
}

.dialogFlexColumn {
  display: flex;
  flex-direction: column;
  padding: 10px;
}


.popupMenuContainer{
  /* padding: 0px 20px 20px 20px; */
  transition: all 0.3s ease-in-out;
  width: 100%;
  background-color: white;
  /* position: absolute; */
  /* top: -25px; */
  border-top: solid rgba(0, 0, 0, 0.1) 1px;
  /* border-radius: 10px 10px 0px 0px; */
  z-index: 1;
}
.popupMenuContainerOpen{
  top: -340px
}

.popupMenuButtonIcon {
  /* margin-left: 20px; */
  font-size: 1.1rem;
  display: flex;
  align-items: center;
  justify-content: center;

  transition: all 0.3s ease-in-out;
}
.popupMenuButtonIconOpen {

  transform: rotate(180deg);

}

.popupMenuButton {
   font-weight: bold;
    /* font-weight: bold; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
  background-color: white;
  outline: none;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom:none;
  padding: 10px 15px;
  height: 40px;
  width: 100%;
  border-radius: 10px 10px 0px 0px;
}

.popupMenuItemContainer {
  transition: all 0.3s ease-in-out;
  height: 0px;
  overflow: hidden;
}

.popupMenuOpen {
  height: 315px;
  overflow-y: auto;
}

.popupMenuItemInnerContainer {
  padding-bottom: 5px;

}

.popupMenuItem {
  font-weight: bold;
  /* font-weight: bold; */
  cursor: pointer;
  box-sizing: border-box;
  width: calc(100% - 20px);
  padding: 0px 10px;
  height: 40px;
  display: flex;
  align-items: center;
  border-radius: 10px;
  margin-left: 10px;
  margin-right: 10px;
  transition: all 0.3s ease-in-out;
  background-color: white;
  /* justify-content: center; */
  /* border-top: 1px rgba(0, 0, 0, 0.1) solid ;
  border-bottom: 1px rgba(0, 0, 0, 0.1) solid ; */
}

.popupMenuItem:hover{

  background-color: #f0f0f0;

}

.calenderContainer{
  display: flex;
  flex-direction: column;
  padding: 10px;
  margin: 2px;
  background-color: white;
  max-width: 260px;
  border-radius: 10px 10px 10px 10px;
  box-shadow: 1px 1px 6px 2px rgba(0, 0, 0, 0.1);
}

.calenderHeaderContainer{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
}

.calenderIcon{
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.calenderIconDisabled{
  color:rgba(0, 0, 0, 0.30);
  pointer-events: none;
}

.calenderHeader{
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  text-align: center;
}

.calenderDateContainer{
  display: flex;
  flex-direction: column;
}
.calenderWeekContainer{
  display: flex;
  flex-direction: row;
  width: 100%;
}


.calenderDate{
  transition: all 0.3 ease-in-out;
  background-color: white;
  color: black;
  padding: 3px;
  margin: 2px;
  border-radius: 5px;
  text-align: center;
  flex:1;
  cursor: pointer;
}

/* .calenderDate:hover{
  background-color: rgba(36,73,248,0.9);
  color:white;
} */

.calenderDateDisabled{
  color:rgba(0, 0, 0, 0.30);
  pointer-events: none;
}

.calenderDateText{
  font-size: 12px;
  color:rgba(0, 0, 0, 0.25);
}

.centerAll{
  display: flex;
  align-items: center;
  justify-content: center;
}


.slide {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
}

.container {
  width: 100%;
  height: 100%;
  position: relative;
}

.imageWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  overflow: hidden;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: contain; /* Adjust image size maintaining aspect ratio */
}

.head {
  text-align: center;
  cursor: pointer;
}

.backendColor {
  background-color: #000000; /* Define your desired background color */
}

.bookAppointmentButton {
  display: block;
  color: #fff;
  padding: 8px;
}