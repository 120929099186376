* {

}
pre{
  padding: 0;
  margin: 0;
  font-family: inherit;
  font-size: inherit;
}
.customScroll{


}
.customScroll::-webkit-scrollbar {
  width: 7px;
}

/* Track */
.customScroll::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.06); */
  /* background-color: rgba(0, 0, 0, 0.06);  */
  /* border-radius: 10px; */
}
 
/* Handle */
.customScroll::-webkit-scrollbar-thumb {
  /* background: #c7d0fc;  */
  background-color: rgba(0, 0, 0, 0.2); 
  border-radius: 10px;
}

/* Handle on hover */
.customScroll::-webkit-scrollbar-thumb:hover {
  /* background-color: rgba(0, 0, 0, 0.2);  */
  /* background: #a8b0d6;  */
}
